import React from "react"
import { Row, Col } from "react-bootstrap"
import MortgageCalculator from "./MortgageCalculator"
import StampDutyCalculator from "./StampDutyCalculator"
import "./assets/styles/_index.scss"
import { graphql, useStaticQuery } from "gatsby"

const PropertyCalculator = props => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            show_mortgage_calculator
          }
        }
      }
    `
  )
  const show_mortgage_calculator = site?.siteMetadata?.show_mortgage_calculator

  return (
    <>
      {show_mortgage_calculator && (
        <MortgageCalculator
          propertyPrice={props?.prop_price ? props?.prop_price : "500000"}
          disablePrice
        />
      )}

      {show_mortgage_calculator && (
        <Row>
          <Col>
            <div className="property-split-line"></div>
          </Col>
        </Row>
      )}

      <StampDutyCalculator
        propertyValue={props?.prop_price ? props?.prop_price : "500000"}
      />
    </>
  )
}

export default PropertyCalculator
